import {
  Box,
  Stack,
  StackDivider,
  VStack,
  Heading,
  Container,
  Spinner,
  Center,
  useToast,
  HStack,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainTopic from "./components/MainTopic";
import { useAuth } from "../../hooks/auth-hook";
import { useQuery } from "react-query";
import axios from "axios";

const BlogDetail = () => {
  const { id } = useParams();

  const toast = useToast();
  const navigate = useNavigate();
  const { token, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const blogsData = useQuery(
    `destinationBlog${id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/destinations/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();

          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      divider={<StackDivider borderColor="gray.200" />}
      style={{ overflow: "hidden" }}
    >
      {!blogsData?.isLoading ? (
        <Container
          w={{ base: "100%", lg: "100%" }}
          h="100%"
          maxW={"7xl"}
          p="12px 5px"
          mb="30px"
        >
          {blogsData?.isSuccess && (
            <VStack paddingTop="20px" spacing="2" alignItems="flex-start">
              <MainTopic blogDetailData={blogsData?.data?.data?.data} />
            </VStack>
          )}
        </Container>
      ) : (
        <Center my="100px">
          <Spinner w="50px" h="50px" />
        </Center>
      )}{" "}
    </Stack>
  );
};

export default BlogDetail;
