import React, { useContext } from "react";
import { Img } from "@chakra-ui/image";
import { Stack, Text } from "@chakra-ui/layout";
import { Box, Heading } from "@chakra-ui/react";
import ReactHtmlParser from "react-html-parser";
// import SocialShare from "./SocialShare";
import demoLogo from "../../../assets/NoImgUploaded (1).jpeg";
import { useLang } from "../../../hooks/lang-hook";

const MainTopic = (props) => {
  const { blogData, blogDetailData } = props;
  console.log({ blogDetailData });
  const { isAmh } = useLang();

  return (
    <Stack
      direction="column"
      spacing={{ base: "15px", sm: "15px", md: "15px" }}
      px={{ base: "15px", sm: "25px", md: "60px" }}
    >
      <Img
        alt="blog pic"
        minH={{ base: "183px", sm: "initial" }}
        maxH={{
          base: "14.25rem",
          sm: "22.375rem",
          md: "29.563rem",
          lg: "25.438rem",
          xl: "27.875rem",
          "2xl": "32rem",
        }}
        objectFit="cover"
        // src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/${blogDetailData.image}`}
        src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${blogDetailData?.image}`}
        // src={demoLogo}
        // onError={addDefaultSrc}
      />
      <Heading as="h2">
        {" "}
        {isAmh && blogDetailData?.title_am
          ? blogDetailData?.title_am
          : blogDetailData?.title}
      </Heading>
      <Text
        style={{ textIndent: "20px" }}
        // color='#686868'
        // fontSize='15px'
        noOfLines={3}
      />
      <Text as="p">
        {!!blogDetailData?.description &&
          ReactHtmlParser(
            isAmh && blogDetailData?.description_am
              ? blogDetailData?.description_am
              : blogDetailData?.description
                  .replaceAll("<pre>", "<p>")
                  .replaceAll("</pre>", "</p>")
          )}
      </Text>
      {/* <SocialShare
        title={blogDetailData?.title}
        description={blogDetailData?.description}
        imageUrl={blogDetailData?.image}
      /> */}
    </Stack>
  );
};

export default MainTopic;
