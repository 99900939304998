import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import BlogDetail from "../destinations/BlogDetail/BlogDetail";
import TodoBlogDetail from "../thingsToDo/BlogDetail/TodoBlogDetail";
const ThingsToDo = React.lazy(() => import("../thingsToDo/ThingsToDo"));
const Destination = React.lazy(() => import("../destinations/Destination"));
const AboutEthiopia = React.lazy(() =>
  import("../aboutEthiopia/AboutEthiopia")
);

const HomePage = React.lazy(() => import("../home/HomePage"));

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} />{" "}
      <Route path="/destinations/:id" element={<Destination />} />
      <Route path="/destinations/detail/:id" element={<BlogDetail />} />{" "}
      <Route path="/things-to-do/detail/:id" element={<TodoBlogDetail />} />
      <Route path="/things-to-do/:id" element={<ThingsToDo />} />
      <Route path="/about-ethiopia" element={<AboutEthiopia />} />

      <Route path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  );
};

export default AuthRoutes;
