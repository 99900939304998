import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [id, setId] = useState("");
  const [token, setToken] = useState(true);
  const [type, setType] = useState(false);
  const [checked, setChecked] = useState(false);

  // const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((id, token, type) => {
    setId(id);
    setToken(token);
    setType(type);
  localStorage.setItem(
      "userData",
      JSON.stringify({
        id,
        token,
        type,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setId(null);
    setToken(null);
    setType(null);
    localStorage.removeItem("userData");
  }, []);

  let loginData;
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData) {
      if (storedData?.token) {
        loginData = login(storedData?.id, storedData?.token, storedData?.type);
        setChecked(true);
      }
      setChecked(true);
    }
    setChecked(true);
  }, [loginData]);

  //Return
  return (
    <AuthContext.Provider value={{ type, id, token, checked, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
