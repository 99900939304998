import React from "react";
import { Box, IconButton, Img, useBreakpointValue } from "@chakra-ui/react";
// Here we have used react-icons package for the icons
// And react-slick as our Carousel Lib
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import mainAksum from "../../assets/maincarousels/aksum.webp";
import mainBale from "../../assets/maincarousels/bale.jpg";
import sophumer from "../../assets/maincarousels/sof-omar_2-min.png";
import entoto from "../../assets/iFqrjJ8VyTnPyrBhsiDrTw2dQjG3aSH4F4kzedY6.jpg";

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 300,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Carousels({ homepageBlogs }) {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(0);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "10px" });

  // These are the images used in the slide
  const cards = [mainBale, mainAksum, sophumer];

  return (
    <Box
      position={"relative"}
      height={{ base: "300px", sm: "600px" }}
      width={"full"}
      overflow={"hidden"}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Slider */}
      <Slider
        pauseOnHover={false}
        {...settings}
        ref={(slider) => setSlider(slider)}
      >
        {!homepageBlogs?.isLoading &&
          homepageBlogs?.isSuccess &&
          homepageBlogs?.data?.data?.data?.todos.map((url, index) => (
            <Img
              objectFit={"cover"}
              key={index}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${url?.image}`}
            />
          ))}
      </Slider>
    </Box>
  );
}
