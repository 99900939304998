import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const LangContext = createContext();

export function useLang() {
  return useContext(LangContext);
}

export function LangProvider({ children }) {
  const [isAmh, setIsAmh] = useState(false);

  const changeLang = useCallback((isAmh, isOo) => {
    setIsAmh(isAmh);

    localStorage.setItem(
      "langData",
      JSON.stringify({
        isAmh,
      })
    );
  }, []);

  let lang;
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("langData"));
    if (storedData) {
      if (storedData.isAmh) {
        lang = changeLang(true, false);
      }
    }
  }, [lang]);

  //Return
  return (
    <LangContext.Provider value={{ isAmh, changeLang }}>
      {children}
    </LangContext.Provider>
  );
}
