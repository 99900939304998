import {
  Button,
  Heading,
  HStack,
  Img,
  Stack,
  StackDivider,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth-hook";
import { thingsToDoData } from "../Data";
import ReactHtmlParser from "react-html-parser";
import { useLang } from "../../hooks/lang-hook";

const ThingsToDo = ({ homepageBlogs }) => {
  const { isAmh } = useLang();
  return (
    <Stack px="5%" divider={<StackDivider borderColor="gray.200" />}>
      <Heading fontSize={"2xl"}>
        {" "}
        {isAmh ? "ኢትዮጵያ ውስጥ ያሉ መዳረሻዎች" : "Destinations in Ethiopia"}{" "}
      </Heading>
      {!homepageBlogs?.isLoading ? (
        <Wrap spacing={"15px"} justify={"center"}>
          {homepageBlogs?.isSuccess &&
            homepageBlogs?.data?.data?.data?.todos &&
            homepageBlogs?.data?.data?.data?.todos.length > 0 &&
            homepageBlogs?.data?.data?.data?.todos.slice(0, 4).map((thing) => (
              <Stack
                boxShadow="0 0 8px rgba(0, 0, 0, 0.11)"
                borderRadius={"10px"}
                p={2}
                _hover={{ boxShadow: "0 0 6px #423434" }}
              >
                <Link to={`/destinations/detail/${thing?.id}`}>
                  <Stack w="270px">
                    <Img
                      w="270px"
                      h="182px"
                      objectFit={"cover"}
                      src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${thing?.image}`}
                    />
                    <Stack spacing={"4px"}>
                      <Text max fontWeight={"semibold"}>
                        {isAmh && thing?.title_am
                          ? thing?.title_am
                          : thing?.title}
                      </Text>
                      {!!thing?.description && (
                        <Text noOfLines={4} maxW="200px" maxH="42px">
                          {ReactHtmlParser(
                            isAmh && thing?.description_am
                              ? thing?.description_am
                              : thing?.description
                                  .replaceAll("<pre>", "<p>")
                                  .replaceAll("</pre>", "</p>")
                          )}
                        </Text>
                      )}{" "}
                    </Stack>{" "}
                  </Stack>
                </Link>
              </Stack>
            ))}
        </Wrap>
      ) : (
        <div />
      )}
    </Stack>
  );
};

export default ThingsToDo;
