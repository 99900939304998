import { Box, Button, Heading, Img, Stack, Text, Wrap } from "@chakra-ui/react";
import React from "react";
import { bigBlogOne, twoBigBlogsData } from "../Data";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import { useLang } from "../../hooks/lang-hook";

const TwoBigBlogs = ({ homepageBlogs }) => {
  const navigate = useNavigate();
  const { isAmh } = useLang();

  return (
    <Wrap px="5%" justify={"center"} spacing={"50px"}>
      {homepageBlogs.isSuccess &&
        homepageBlogs?.data?.data?.data?.popular_destinations &&
        homepageBlogs?.data?.data?.data?.popular_destinations
          .slice(0, 2)
          .map((blog) => {
            const { id, title, image, description, title_am, description_am } =
              blog;
            return (
              <Stack
                w={{ base: "90%", md: "45%" }}
                py="2%"
                bgColor={"#F5F5F5"}
                spacing={"25px"}
                justify={"center"}
                align={"center"}
              >
                <Img
                  src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${image}`}
                  w="100%"
                />
                <Stack spacing={"10px"} px="1%">
                  <Heading fontSize={"2xl"} alignSelf={"start"}>
                    {isAmh && title_am ? title_am : title}
                  </Heading>
                  {!!description && (
                    <Text color="#5B546B" noOfLines={4}>
                      {ReactHtmlParser(
                        isAmh && description_am
                          ? description_am
                          : description
                              .replaceAll("<pre>", "<p>")
                              .replaceAll("</pre>", "</p>")
                      )}
                    </Text>
                  )}
                </Stack>
                <Box pl="5" alignSelf={"start"}>
                  <Button
                    bgColor={"#423434"}
                    color="#FFF"
                    _hover={{
                      bgColor: "#FFF",
                      color: "#423434",
                      border: "1px solid #423434",
                    }}
                    onClick={() => navigate(`/destinations/detail/${id}`)}
                  >
                    {isAmh ? "ተጨማሪ ያንብቡ" : "Read More"}
                  </Button>
                </Box>
              </Stack>
            );
          })}
    </Wrap>
  );
};

export default TwoBigBlogs;
