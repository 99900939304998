import { Box, Button, Heading, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router-dom";
import { useLang } from "../../hooks/lang-hook";

const BigBlogTwo = ({ bigBlog }) => {
  const { id, title, image, description, title_am, description_am } = bigBlog;
  const navigate = useNavigate();
  const { isAmh } = useLang();

  return (
    <Box px="5%">
      <Stack
        py="2%"
        bgColor={"#F5F5F5"}
        spacing={"25px"}
        justify={"center"}
        align={"center"}
      >
        <Img
          src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${image}` || image}
          w="100%"
        />
        <Stack spacing={"10px"} px="1%">
          <Heading alignSelf={"start"}>
            {" "}
            {isAmh && title_am ? title_am : title}
          </Heading>
          {!!description && (
            <Text color="#5B546B" noOfLines={4}>
              {ReactHtmlParser(
                isAmh && description_am
                  ? description_am
                  : description
                      .replaceAll("<pre>", "<p>")
                      .replaceAll("</pre>", "</p>")
              )}
            </Text>
          )}
        </Stack>
        <Box pl="5" alignSelf={"start"}>
          <Button
            bgColor={"#423434"}
            color="#FFF"
            _hover={{
              bgColor: "#FFF",
              color: "#423434",
              border: "1px solid #423434",
            }}
            onClick={() => navigate(`/destinations/detail/${id}`)}
          >
            {" "}
            {isAmh ? "ተጨማሪ ያንብቡ" : "Read More"}{" "}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default BigBlogTwo;
