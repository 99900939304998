import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  HStack,
  useToast,
  Button,
} from "@chakra-ui/react";
import axios from "axios";
import { ReactNode, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth-hook";
import { useLang } from "../../hooks/lang-hook";
import { Logo } from "./Logo";
const users = JSON.parse(localStorage.getItem("culture"));

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={"_blank"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { isAmh } = useLang();

  const { token, type, logout } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const [NAV_ITEMS, setNavItem] = useState([]);
  console.log({ NAV_ITEMS });
  const navigationItems = useQuery(
    `navigationItems`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/navigation`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
      onSuccess: (res) => {
        let NAV_ITEMS = [
          { label: "Destinations", children: [] },
          { label: "Things to do", children: [] },
          {
            label: "About Ethiopia",
            id: 1,
            href: "/about-ethiopia",
          },
        ];
        res?.data?.data?.destination_categories.map((data) =>
          NAV_ITEMS[0]?.children.push(data)
        );
        res?.data?.data?.todo_categories.map((data) =>
          NAV_ITEMS[1]?.children.push(data)
        );
        console.log({ NAV_ITEMS });
        setNavItem(NAV_ITEMS);
      },
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();

          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  console.log({ navigationItems });

  return (
    <Box
      display={token && type === "admin" ? "none" : "flex"}
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      {" "}
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1.5fr 1.5fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box cursor={"pointer"}>
              <HStack spacing={"6px"}>
                <Logo />
                <Text>
                  {" "}
                  {isAmh ? "የኢትዮ ባህል ምክሮች" : "Ethio Culture Tips"}
                </Text>{" "}
              </HStack>
            </Box>
            <Text fontSize={"sm"}>
              {isAmh
                ? `© ${
                    1900 + new Date().getYear()
                  } የኢትዮ-ባህል ምክሮች። መብቱ በህግ የተጠበቀ ነው`
                : `© ${
                    1900 + new Date().getYear()
                  } Ethio-culture tips. All rights
                reserved`}
            </Text>
            {navigationItems?.isSuccess && (
              <Stack direction={"row"} spacing={6}>
                <SocialButton label={"Twitter"} href={"https://twitter.com"}>
                  <img src="https://img.icons8.com/color/48/000000/twitter--v1.png" />{" "}
                </SocialButton>
                <SocialButton label={"YouTube"} href={"https://youtube.com"}>
                  <img src="https://img.icons8.com/external-prettycons-flat-prettycons/47/000000/external-youtube-multimedia-prettycons-flat-prettycons.png" />{" "}
                </SocialButton>
                <SocialButton label={"Faceboook"} href={"https://facebook.com"}>
                  <img src="https://img.icons8.com/fluency/48/000000/facebook-new.png" />{" "}
                </SocialButton>

                <SocialButton
                  label={"Instagram"}
                  href={"https://instagram.com"}
                >
                  <img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" />
                </SocialButton>
              </Stack>
            )}
          </Stack>
          {navigationItems?.isSuccess && users?.token.length > 10 && (
            <>
              <Stack align={"flex-start"}>
                <ListHeader>{isAmh ? "መዳረሻዎች" : "Destinations"}</ListHeader>
                {navigationItems?.data?.data?.data?.destination_categories
                  .slice(0, 5)
                  .map((data) => (
                    <Link to={`/destinations/${data?.id}`}>
                      {isAmh && data?.name_am ? data?.name_am : data?.name}
                    </Link>
                  ))}
              </Stack>
              <Stack align={"flex-start"}>
                <ListHeader>{isAmh ? "የሚደረጉ ነገሮች" : "Things to do"}</ListHeader>
                {navigationItems?.data?.data?.data?.todo_categories
                  .slice(0, 5)
                  .map((data) => (
                    <Link to={`/things-to-do/${data?.id}`}>
                      {isAmh && data?.name_am ? data?.name_am : data?.name}
                    </Link>
                  ))}
              </Stack>
            </>
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
