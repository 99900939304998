import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  HStack,
  useToast,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Logo } from "./Logo";
import { Nav } from "./Navigation.elements";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth-hook";
import { useQuery } from "react-query";
import axios from "axios";
import { useLang } from "../../hooks/lang-hook";
const users = JSON.parse(localStorage.getItem("culture"));

export default function MainNavigation() {
  const { isOpen, onToggle } = useDisclosure();

  const toast = useToast();
  const navigate = useNavigate();

  const { token, logout } = useAuth();
  const { isAmh } = useLang();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const [NAV_ITEMS, setNavItem] = useState([]);
  console.log({ NAV_ITEMS });
  const navigationItems = useQuery(
    `navigationItems`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/navigation`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        let NAV_ITEMS = [
          {
            label: "Destinations",
            label_am: "መዳረሻዎች",
            children: [],
          },
          {
            label: "Things to do",
            label_am: "የሚታዩና የሚደረጉ ነገሮች",
            children: [],
          },
          {
            label: "About Ethiopia",
            label_am: "ስለ ኢትዮጵያ",

            id: 1,
            href: "/about-ethiopia",
          },
        ];
        res?.data?.data?.destination_categories.map((data) =>
          NAV_ITEMS[0]?.children.push(data)
        );
        res?.data?.data?.todo_categories.map((data) =>
          NAV_ITEMS[1]?.children.push(data)
        );
        console.log({ NAV_ITEMS });
        setNavItem(NAV_ITEMS);
      },
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();

          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  console.log({ navigationItems });

  return (
    <Nav>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        bgColor="#423434"
        color="#FFF"
      >
        <Flex flex={{ base: 1 }} justify={{ base: "start", md: "start" }}>
          <Link to="/">
            <HStack spacing={"6px"}>
              <Logo />
              <Text>
                {" "}
                {isAmh ? "የኢትዮ ባህል ምክሮች" : "Ethio Culture Tips"}
              </Text>{" "}
            </HStack>
          </Link>
        </Flex>{" "}
        <Flex display={{ base: "none", md: "flex" }} ml={10}>
          <DesktopNav navigationItems={navigationItems} NAV_ITEMS={NAV_ITEMS} />{" "}
        </Flex>
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
          justify="end"
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav navigationItems={navigationItems} NAV_ITEMS={NAV_ITEMS} />
      </Collapse>
    </Nav>
  );
}

const DesktopNav = ({ NAV_ITEMS, navigationItems }) => {
  const linkColor = useColorModeValue("#FFF");
  const linkHoverColor = useColorModeValue("gray.200", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const { token, logout } = useAuth();
  const { isAmh, changeLang } = useLang();
  const navigate = useNavigate();

  const loggMeOut = () => {
    navigate("/");
    localStorage.removeItem("culture");
    window.location.reload();
  };
  return (
    <Stack direction={"row"} spacing={4} justify="center">
      <>
        <Button
          bgColor={"#423434"}
          color="#FFF"
          _hover={{
            bgColor: "#FFF",
            color: "#423434",
            border: "1px solid #423434",
          }}
          onClick={() => changeLang(!isAmh)}
        >
          {isAmh ? "English" : "አማርኛ"}
        </Button>
        {users?.token.length > 10 ? (
          <Stack direction={"row"} spacing={4} justify="center">
            {navigationItems?.isSuccess &&
              NAV_ITEMS.map((navItem) => {
                console.log({ navItem });
                return (
                  <Stack key={navItem.name}>
                    <Popover trigger={"hover"} placement={"bottom-start"}>
                      <PopoverTrigger>
                        <Box
                          p={2}
                          href={navItem.href ?? "#"}
                          fontSize={"sm"}
                          fontWeight={500}
                          color={linkColor}
                          _hover={{
                            textDecoration: "none",
                            color: linkHoverColor,
                          }}
                        >
                          <Link to={!navItem?.children ? navItem?.href : "#"}>
                            {isAmh ? navItem?.label_am : navItem.label}
                          </Link>
                        </Box>
                      </PopoverTrigger>

                      {navItem.children && (
                        <PopoverContent
                          border={0}
                          boxShadow={"xl"}
                          p={4}
                          rounded={"xl"}
                          minW={"sm"}
                          bg="#423434"
                        >
                          <Stack>
                            {navItem.children.map((child, index) => (
                              <DesktopSubNav
                                mainTitle={navItem?.label}
                                key={child.label}
                                {...child}
                              />
                            ))}{" "}
                          </Stack>
                        </PopoverContent>
                      )}
                    </Popover>
                  </Stack>
                );
              })}
            {users?.token?.length > 10 && (
              <Button
                bgColor={"#423434"}
                color="#FFF"
                _hover={{
                  bgColor: "#FFF",
                  color: "#423434",
                  border: "1px solid #423434",
                }}
                onClick={() => loggMeOut()}
              >
                {isAmh ? "ውጣ" : "Logout"}
              </Button>
            )}
          </Stack>
        ) : null}
      </>
    </Stack>
  );
};

const DesktopSubNav = ({
  id,
  mainTitle,
  name,
  name_am,
  href,
  subLabel,
  index,
}) => {
  console.log({ id });
  const { isAmh } = useLang();
  return (
    <Link
      to={
        mainTitle === "Destinations"
          ? `/destinations/${id}`
          : mainTitle === "Things to do"
          ? `/things-to-do/${id}`
          : `#`
      }
    >
      <Box
        href={href}
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"}
        _hover={{
          bg: useColorModeValue("pink.50", "gray.900"),
        }}
      >
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "#423434" }}
              fontWeight={500}
            >
              {isAmh && name_am ? name_am : name}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
          >
            <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
    </Link>
  );
};

const MobileNav = ({ navigationItems, NAV_ITEMS, mainTitle }) => {
  const { logout, token } = useAuth();
  const { isAmh, changeLang } = useLang();
  const navigate = useNavigate();

  const loggMeOut = () => {
    navigate("/");
    localStorage.removeItem("culture");
    window.location.reload();
  };
  return (
    <Stack bg="#423434" mt={-1} p={4} display={{ md: "none" }}>
      <Button
        bgColor={"#423434"}
        color="#FFF"
        _hover={{
          bgColor: "#FFF",
          color: "#423434",
          border: "1px solid #423434",
        }}
        onClick={() => changeLang(!isAmh)}
      >
        {isAmh ? "English" : "አማርኛ"}
      </Button>
      {navigationItems?.isSuccess &&
        users?.token.length &&
        NAV_ITEMS.map((navItem) => {
          console.log({ navItem });
          return (
            <MobileNavItem
              key={navItem.label}
              mainTitle={isAmh ? navItem?.label_am : navItem.label}
              label={navItem.label}
              href={navItem?.href}
              {...navItem}
            />
          );
        })}
      {users?.token.length > 10 && (
        <Button
          bgColor={"#423434"}
          color="#FFF"
          _hover={{
            bgColor: "#FFF",
            color: "#423434",
            border: "1px solid #423434",
          }}
          onClick={() => loggMeOut()}
        >
          {isAmh ? "ውጣ" : "Logout"}
        </Button>
      )}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, id, mainTitle }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isAmh } = useLang();
  console.log({ mainTitle });
  return (
    <Box py={2}>
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          // as={Link}
          href={href ?? "#"}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
        >
          <Link to={!children ? href : "#"}>
            <Text cursor={"pointer"} fontWeight={600} color="#FFF">
              {mainTitle}{" "}
            </Text>
          </Link>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: "0!important" }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={useColorModeValue("gray.200", "gray.700")}
            align={"start"}
          >
            {children &&
              children.map((child) => (
                <Box w="100%">
                  <Link
                    to={
                      label === "Destinations"
                        ? `/destinations/${child?.id}`
                        : label === "Things to do"
                        ? `/things-to-do/${child?.id}`
                        : `#`
                    }
                  >
                    <Box
                      color="#f7fafc"
                      key={isAmh ? child?.label_am : child.label}
                      py={2}
                      href={child.href}
                    >
                      {isAmh && child?.name_am ? child?.name_am : child?.name}
                    </Box>
                  </Link>
                </Box>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    </Box>
  );
};
