import {
  Button,
  Center,
  Heading,
  HStack,
  Img,
  Spinner,
  Stack,
  Text,
  useToast,
  Wrap,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";
import demoLogo from "../assets/maincarousels/bale.jpg";
import DynamicCarousels from "../shared/components/DynamicCarousels";
import { useLang } from "../hooks/lang-hook";
const ThingsToDo = () => {
  const { id } = useParams();
  console.log({ id });

  const toast = useToast();
  const navigate = useNavigate();
  const { token, logout } = useAuth();
  const { isAmh } = useLang();
  console.log({ isAmh });
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const todoData = useQuery(
    `todoData${id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/todo-categories/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();

          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  console.log({ todoData });

  //FUnction
  const addDefaultSrc = (ev) => {
    ev.target.src = demoLogo;
  };

  let cards = [];
  todoData?.data?.data?.data?.todo_subcategories.map((sub) => {
    sub?.todos.map((attr) =>
      cards.push(`${process.env.REACT_APP_BACKEND_FILE_URL}/${attr?.image}`)
    );
  });
  console.log({ cards });

  return (
    <>
      {!todoData?.isLoading ? (
        <>
          {todoData?.isSuccess && (
            <Stack>
              <DynamicCarousels cards={cards} />{" "}
              <Heading pt="30px" px="5%">
                {isAmh && todoData?.data?.data?.data?.name_am
                  ? todoData?.data?.data?.data?.name_am
                  : todoData?.data?.data?.data?.name}{" "}
              </Heading>
              <Stack px="5%">
                <Text fontSize={"18px"} textAlign={"justify"} maxW={"6xl"}>
                  {" "}
                  {isAmh && todoData?.data?.data?.data?.description_am
                    ? todoData?.data?.data?.data?.description_am
                    : todoData?.data?.data?.data?.description}{" "}
                </Text>
                <Stack>
                  {todoData?.data?.data?.data?.todo_subcategories.map(
                    (attraction) => (
                      <Stack>
                        <Text fontSize={"20px"} fontWeight="bold">
                          {isAmh && attraction?.name_am
                            ? attraction?.name_am
                            : attraction?.name}
                        </Text>
                        <Wrap>
                          {attraction?.todos.map((attr) => (
                            <Stack
                              boxShadow="0 0 8px rgba(0, 0, 0, 0.11)"
                              borderRadius={"10px"}
                              p={2}
                              _hover={{ boxShadow: "0 0 6px #423434" }}
                            >
                              <Link to={`/things-to-do/detail/${attr?.id}`}>
                                <Stack w="270px">
                                  <Img
                                    objectFit={"cover"}
                                    maxH={"170px"}
                                    minH={"170px"}
                                    w="270px"
                                    src={`${process.env.REACT_APP_BACKEND_FILE_URL}/${attr?.image}`}
                                  />
                                  <Wrap>
                                    <Text fontWeight={"semibold"}>
                                      {isAmh && attr?.title_am
                                        ? attr?.title_am
                                        : attr?.title}
                                    </Text>
                                    <Text noOfLines={"2"}>
                                      {attr?.overview}
                                    </Text>
                                  </Wrap>
                                </Stack>
                              </Link>
                            </Stack>
                          ))}
                        </Wrap>
                      </Stack>
                    )
                  )}
                </Stack>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <Center my="100px">
          <Spinner w="50px" h="50px" />
        </Center>
      )}{" "}
    </>
  );
};

export default ThingsToDo;
