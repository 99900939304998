import logo from "./logo.svg";
import "./App.css";
import AuthRoutes from "./routes/AuthRoutes";
import { Suspense, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "./shared/components/Footer";
import { Center, Spinner, Stack } from "@chakra-ui/react";
import MainNavigation from "./shared/components/MainNavigation";
import { useAuth } from "./hooks/auth-hook";
import { AuthContext } from "./context/AuthContext";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import AdminAuthRoutes from "./routes/AdminAuthRoutes";

function App() {
  let routes;
  const { id, token, login, type, checked, logout } = useAuth();
  console.log({ token });

  if (token && type === "user") {
    routes = <AuthRoutes />;
  } else if (token && type === "admin") {
    routes = <AdminAuthRoutes />;
  } else if (checked) {
    routes = <NoAuthRoutes />;
  }
  const [click, setClick] = useState(false);

  return (
    <Router>
      <ScrollToTop />
      {((token && type !== "admin") || !token) && <MainNavigation />}
      {/* <MainNavigation fromApp = {true} {...mainNavData} /> */}
      <Stack spacing={"30px"}>
        <main>
          {" "}
          <Suspense
            fallback={
              <Center my="100px">
                <Spinner w="50px" h="50px" />
              </Center>
            }
          >
            {checked ? (
              routes
            ) : (
              <Center my="100px">
                <Spinner w="50px" h="50px" />
              </Center>
            )}{" "}
          </Suspense>
        </main>
        <Footer />
      </Stack>
    </Router>
  );
}

export default App;
