import { Button, HStack, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import BigBlog from "./components/BigBlog";
import Carousels from "./components/Carousels";
import MostPopular from "./components/MostPopular";
import SafeTravels from "./components/SafeTravels";
import ThingsToDo from "./components/ThingsToDo";
import TwoBigBlogs from "./components/TwoBigBlogs";
import { bigBlogOne, bigBlogTwo } from "./Data";
import { useQuery } from "react-query";

import { useAuth } from "../hooks/auth-hook";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BigBlogTwo from "./components/BigBlogTwo";
const HomePage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { token, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: `Bearer ${token}`,
  };

  const homepageBlogs = useQuery(
    `homepageBlogs`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();

          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
        }
      },
    }
  );
  return (
    <Stack spacing={"20px"}>
      <Carousels homepageBlogs={homepageBlogs} />
      <SafeTravels />
      <ThingsToDo homepageBlogs={homepageBlogs} />
      {homepageBlogs?.data?.data?.data?.popular_todos[0] && (
        <BigBlog bigBlog={homepageBlogs?.data?.data?.data?.popular_todos[0]} />
      )}
      <MostPopular homepageBlogs={homepageBlogs} />
      <TwoBigBlogs homepageBlogs={homepageBlogs} />
      {homepageBlogs?.data?.data?.data?.popular_destinations[0] && (
        <BigBlogTwo
          bigBlog={homepageBlogs?.data?.data?.data?.popular_destinations[0]}
        />
      )}
    </Stack>
  );
};

export default HomePage;
