import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const School = React.lazy(() => import("../admin/components/Admin"));
const Dashboard = React.lazy(() => import("../admin/dashboard/Dashboard"));

const ErrorPage = React.lazy(() => import("../shared/components/ErrorPage"));
const AddBlog = React.lazy(() => import("../admin/blogs/AddBlog/AddBlog"));
const ManageBlogs = React.lazy(() =>
  import("../admin/blogs/ManageBlogs/ManageBlogs")
);
const ManageDestinations = React.lazy(() =>
  import("../admin/destinations/ManageDestinations")
);
const ManageThingstodo = React.lazy(() =>
  import("../admin/thingstodos/ManageThingstodo")
);

const AdminAuthRoutes = () => {
  //Hook

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/manage-blogs" />} />
        <Route path="/" element={<School />}>
          <Route path="add-blog" element={<AddBlog />} />
          <Route path="manage-blogs" element={<ManageBlogs />} />
          <Route path="manage-destinations" element={<ManageDestinations />} />
          <Route path="manage-thingstodos" element={<ManageThingstodo />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default AdminAuthRoutes;
