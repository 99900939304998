import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Img,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import showPassIcon from "../../assets/password/show.webp";
import hidePassIcon from "../../assets/password/hide.webp";
import { useAuth } from "../../hooks/auth-hook";
import { useLang } from "../../hooks/lang-hook";
// Import React FilePond

export default function SimpleCard() {
  const auth = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCheck, setShowCheck] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isAmh } = useLang();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const loginMutation = useMutation(
    async (newLogin) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/admin-login",
        newLogin,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, isAmh ? "በጣም አጭር!" : "Too Short!"),
  });
  console.log({ loginMutation });
  const onSubmit = async ({ email, password }) => {
    try {
      loginMutation.mutate(
        {
          email,
          password,
        },
        {
          onSuccess: (responseData) => {
            // eslint-disable-next-line no-console

            if (responseData?.data?.status) {
              auth.login(
                responseData?.data?.data?.id,
                responseData?.data?.data?.token,
                "admin"
              );
              toast({
                title: "Logged in successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            } else {
              // setError(responseData?.data?.message);
            }

            navigate("/");
          },
          onError: (responseData) => {
            toast({
              title:
                responseData?.message ||
                responseData?.response?.data?.message ||
                "Error on login",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {}
  };

  return (
    <Flex
      minH={"70vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack maxH="0px" align={"center"} visibility="hidden">
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(values) => {
            return (
              <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                <Form>
                  <Stack spacing={8}>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl id="email">
                          <FormLabel>Email address</FormLabel>
                          <Input {...field} placeholder="Email" type="email" />
                          {values?.errors?.email && values?.touched?.email && (
                            <Text color="red">{values?.errors?.email}</Text>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password">
                      {({ field, form }) => (
                        <FormControl id="password">
                          <FormLabel>Password</FormLabel>
                          <InputGroup size="md">
                            <Input
                              {...field}
                              id="password"
                              placeholder="password"
                              type={showPassword ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Img
                                  w="25px"
                                  src={
                                    showPassword ? showPassIcon : hidePassIcon
                                  }
                                />
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          {values?.errors?.password &&
                            values?.touched?.password && (
                              <Text color="red">
                                {values?.errors?.password}
                              </Text>
                            )}
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={10}>
                      <Button
                        disabled={
                          !(
                            values.isValid &&
                            values.dirty &&
                            !loginMutation?.isLoading
                          )
                        }
                        bg={"#423434"}
                        color={"white"}
                        _hover={{
                          bg: "#FFF",
                          color: "#423434",
                          border: "1px solid #423434",
                        }}
                        isLoading={loginMutation?.isLoading}
                        type="submit"
                      >
                        Sign in
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </Stack>
    </Flex>
  );
}
