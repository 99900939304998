import { Heading, Img, Stack, Text } from "@chakra-ui/react";
import React from "react";
import about from "../assets/about-us.png";
import { useAuth } from "../hooks/auth-hook";
import { useLang } from "../hooks/lang-hook";

const aboutData = {
  img: about,
  title: "About Ethiopia",
  description: "",
};

const AboutEthiopia = () => {
  const { isAmh } = useLang();
  return (
    <Stack justify={"center"}>
      <Img w="100%" src={about} />
      <Stack maxW={"7xl"} px="5%" spacing={"20px"}>
        <Heading>{isAmh ? `ስለ ኢትዮጵያ` : `About Ethiopia`}</Heading>
        <Stack spacing={"20px"} color="#7A7A7A" fontSize={"18px"}>
          <Text style={{ textIndent: 20 }}>
            {isAmh
              ? `ኢትዮጵያ በዩኔስኮ በአለም ቅርስነት የተመዘገቡ ዘጠኝ ቅርሶች ውስጥ የተንፀባረቁበት ልዩ የባህል ቅርሶቿ፣ 
              የዳበረ ታሪኳ እና አስደናቂ የብዝሃ ህይወት ታሪክ የተንፀባረቁባት ጥንታዊት ሀገር ነች - ከሌሎች የአፍሪካ 
              ሀገራት በበለጠ። በድንበሯ ውስጥ፣ ከሰሃራ በስተደቡብ ከሰሃራ በስተደቡብ ካሉት ጥንታዊቷ ከተማ ጋር በአለም 
              አራተኛዋ ቅድስት እስላማዊ ከተማ ታገኛላችሁ። አስደናቂው ጥንታዊ ቅርሶች የመካከለኛው ዘመን የላሊበላ እና 
              የገራልታ ውቅር አብያተ ክርስቲያናት፣ ከ3,000 ዓመታት በፊት የነበሩ የፈራረሱ ቤተመንግሥቶች እና ቤተመቅደሶች፣ 
              የ17ኛው ክፍለ ዘመን የጎንደር ድንቅ ቤተመንግሥቶች፣ በፕላኔታችን ላይ በየትኛውም ቦታ የተገኙ ጥንታዊ የሰው ልጅ 
              ቅሪተ አካላት ይገኙበታል። በዚህ ላይ ውበቱን የሲሚን እና የባሌ ተራሮች፣ አስደናቂው የእሳተ ጎመራው የደናክል ጭንቀት፣ 
              የአጥቢ እንስሳትና አእዋፍ ሀብት ሌላም ቦታ የማይገኝ ሲሆን ኢትዮጵያ በጣም ማራኪ እና ተወዳጅ የድንገተኛ
               የቱሪስት መዳረሻ ሆና መሆኗ ብዙም አያስደንቅም። በአፍሪካ ውስጥ.`
              : `Ethiopia is an ancient country whose unique cultural heritage, rich
            history, and remarkable biodiversity are reflected in a tally of
            nine UNESCO World Heritage Sites – more than any other country in
            Africa. Within its borders, you’ll find the world’s fourth-holiest
            Islamic city, along with the oldest continuously occupied town south
            of the Sahara. Compelling antiquities include the medieval rock-hewn
            churches of Lalibela and Gheralta, ruined palaces and temples dating
            back 3,000 years, the magnificent 17th-century castles of Gondar,
            and the oldest human fossils unearthed anywhere on the planet. Add
            to this the beautiful Simien and the Bale Mountains, the spectacular
            volcanic landscapes of the Danakil Depression, and a wealth of
            mammals and birds found nowhere else in the world, and it’s little
            wonder that Ethiopia has become the most attractive and popular
            emergent tourist destination in Africa.`}
          </Text>
          <Text>
            {isAmh
              ? `የኢትዮጵያ አስደናቂ እና አስደናቂ ታሪክ የተመዘገበው ከንግሥተ ሳባ እና ከንጉሥ ሰሎሞን ዘመነ መንግሥት 3,000 ዓመታት በፊት ነው።`
              : ` Ethiopia’s fascinating and enigmatic recorded history stretches back
            3,000 years to the reign of the Queen of Sheba and King Solomon.`}
          </Text>
          <Text>
            {isAmh
              ? `የኢትዮጵያ የብዝሀ ህይወት ሀብት ከ50 በላይ አጥቢ እንስሳት እና የአእዋፍ ዝርያዎችን በሚያቀፉ የተለያዩ የእፅዋት እና የእንስሳት ዝርያዎች ተንጸባርቋል። ከነዚህም ውስጥ ከአስደናቂው የኢትዮጵያ ተኩላ እስከ አስደናቂው ራስፖሊ ቱራኮ።`
              : `Ethiopia’s rich biodiversity is reflected in varied flora and fauna
            embracing more than 50 endemic species of mammals and birds, ranging
            from the iconic Ethiopian wolf to the spectacular Raspoli’s turaco.`}
          </Text>
          <Text>
            {isAmh
              ? `ከ1994 ዓ.ም ጀምሮ የተረጋጋ እና ሰላማዊ ዲሞክራሲ የሰፈነባት፣ ኢትዮጵያ ዛሬ ፌዴራላዊ መንግስት ስትሆን ተራማጅ የኢኮኖሚ ፖሊሲዋ የተንፀባረቀችበት ከሺህ አመት በኋላ ያስመዘገበው አማካይ ዓመታዊ አማካይ 10 በመቶ እድገት ነው።`
              : `  A stable and peaceful democracy since 1994, Ethiopia Today is a
            federal state whose progressive economic policies are reflected is a
            post-millennial average annual growth rate of around 10 percent.`}
          </Text>
          <Text>
            {isAmh
              ? `ልዩ የሆነ የሙዚቃ ቅርስ እና ምግብ ከጥንታዊ የቡና ባህል እና የዳበረ የጥበብ እና የእደ ጥበብ ትእይንት ጋር የኢትዮጵያ አኗኗር ብዙ ጉጉት ያላቸው ጎብኝዎችን የሚያቀርብ መሆኑን ያረጋግጣል።`
              : `A unique musical heritage and cuisine, together with ancient coffee
            culture and thriving arts and crafts scene, ensure that the
            Ethiopian lifestyle has much to offer curious visitors.`}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AboutEthiopia;
