import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLogin from "../auth/AdminLogin/AdminLogin";
import AboutEthiopia from "../aboutEthiopia/AboutEthiopia";
import Destination from "../destinations/Destination";
import BlogDetail from "../destinations/BlogDetail/BlogDetail";
import TodoBlogDetail from "../thingsToDo/BlogDetail/TodoBlogDetail";
import ThingsToDo from "../thingsToDo/ThingsToDo";
import HomePage from "../home/HomePage";
const Login = React.lazy(() => import("../auth/UserLogin/Login"));

const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />{" "}
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/home" element={<HomePage />} />{" "}
      <Route path="/destinations/:id" element={<Destination />} />
      <Route path="/destinations/detail/:id" element={<BlogDetail />} />{" "}
      <Route path="/things-to-do/detail/:id" element={<TodoBlogDetail />} />
      <Route path="/things-to-do/:id" element={<ThingsToDo />} />
      <Route path="/about-ethiopia" element={<AboutEthiopia />} />
    </Routes>
  );
};

export default NoAuthRoutes;
