import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useLang } from "../../hooks/lang-hook";
import { Logo } from "../../shared/components/Logo";

const SafeTravels = () => {
  const { isAmh } = useLang();
  return (
    <Stack
      p={{ base: "2% 5%", md: "2% 10%" }}
      justify={"center"}
      direction={{ base: "column", md: "row" }}
      spacing={{ base: "10px", md: "40px" }}
    >
      <Text fontSize={"18px"}>
        {isAmh
          ? `የዓለም የጉዞ እና የጉዞ ሽልማት የተሸለመው የመጨረሻ መዳረሻ ኢትዮጵያ ነች።
         የቱሪዝም ካውንስል (WTTC) ደህንነቱ የተጠበቀ የጉዞ ማህተም - በዓለም የመጀመሪያው
         የነዋሪዎችን ደህንነት ለማረጋገጥ ዓለም አቀፍ ደህንነት እና ንፅህና ማህተም ፣
         ተጓዦች, እና በቱሪዝም እሴት ሰንሰለት ውስጥ ያሉ ሰራተኞች እና ንግዶች, እንደ
         ኢትዮጵያ ድንበሯን ለአለም አቀፍ ጎብኚዎች ከፍታለች።`
          : `Ethiopia is the latest destination to be awarded the World Travel &
        Tourism Council (WTTC) Safe Travels Stamp – the world’s first-ever
        global safety and hygiene stamp to ensure safety for residents,
        travelers, and workers and businesses in the tourism value chain, as
        Ethiopia reopened its borders to international visitors.`}
      </Text>
    </Stack>
  );
};

export default SafeTravels;
